import React from "react"
import { FaAward } from "react-icons/fa"
import { FiUsers } from "react-icons/fi"
import { VscFolderLibrary } from "react-icons/vsc"

import "./about.css"
import ME from "../../assets/mohammed-najib.jpg"

const About = () => {
  const experienceYears = new Date().getFullYear() - 2018

  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Mohammed Najib" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>{experienceYears}+ Years Working</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>10+ Worldwide</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>110+ Completed</small>
            </article>
          </div>

          <p>
            I enjoy programming and learning new programming techniques,
            frameworks, libraries and languages. In the last 2 years, I focused
            on programming with flutter (for mobile apps) and react (for
            websites). I think of myself as a problem solver. Self-learner and
            quick to gain knowledge. Motivated to work in a team and I can work
            solo. I have worked as both mobile and web developer since 2018.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  )
}

export default About
