import React from "react"

import "./portfolio.css"
import RESPONSIVE_WEB_PAGE from "../../assets/responsive-web-page-1.png"
import IMG_KNOWLEDGE_BANK from "../../assets/knowledge-bank.png"
import NEXTJS_GENERATIVE_AI_PALM2 from "../../assets/nextjs_generative_ai_palm2.png"
import FLUTTER_PDF_VIEWER from "../../assets/flutter-pdf-viewer.png"
import VUE3_EXPENSE_TRACKER from "../../assets/vue3_expense_tracker.png"
import FLUTTER_CARD_GAME from "../../assets/flutter_card_game.png"
import CONTACTS_APP from "../../assets/contacts-app.png"
import ADMIN_DASHBOARD_EXAMPLE_1 from "../../assets/admin-dashboard-example-1.png"
import FLUTTER_INVOICE_PDF_GENERATOR from "../../assets/flutter-invoice-pdf-generator.png"
import FLUTTER_CAPTURING_AND_PLAYING_AUDIO from "../../assets/flutter_capturing_and_playing_audio.png"
import ECommerceSofa from "../../assets/ecommerce_sofa.png"
import MD_AI from "../../assets/md-ai.png"
import ANIMATED_LOGIN_PAGE_WEB from "../../assets/animated-login-page-web.png"

const data = [
  {
    id: 1,
    image: MD_AI,
    title: "MD-AI, Have conversations with your PDF documents (site)",
    github: null,
    demo: null,
    site: "https://md-ai.mohammed-najib.me/",
  },
  {
    id: 2,
    image: ANIMATED_LOGIN_PAGE_WEB,
    title: "A simple Login page for web written for Arabic viewers (site)",
    github: "https://github.com/mohammed-najib/animated-login-page",
    demo: "https://animated-login-page.mini-projects.mohammed-najib.me/",
    site: null,
  },
  {
    id: 3,
    image: ADMIN_DASHBOARD_EXAMPLE_1,
    title: "An admin dashboard design for a website (Website-UI)",
    github: null,
    demo: "https://admin-dashboard-example-1.mohammed-najib.me/",
    site: null,
  },
  {
    id: 4,
    image: ECommerceSofa,
    title: "An e-commerce website with PayPal payment (site)",
    github: "https://github.com/mohammed-najib/react_eshop_with_paypal",
    demo: null,
    site: null,
  },
  {
    id: 5,
    image: RESPONSIVE_WEB_PAGE,
    title: "Portfolio website that shows Responsiveness (site)",
    github: "https://github.com/mohammed-najib/responsive-web-page-1",
    demo: "https://responsive-web-page-1.mohammed-najib.me",
    site: null,
  },
  {
    id: 6,
    image: FLUTTER_CARD_GAME,
    title: "A card game app with two games: Crazy 8s and 31 (desktop)",
    github: "https://github.com/mohammed-najib/flutter_card_game",
    demo: null,
    site: null,
  },
  {
    id: 7,
    image: CONTACTS_APP,
    title: "An alternative to in-device contacts app (android)",
    github: "https://github.com/mohammed-najib/flutter_card_game",
    demo: null,
    site: null,
  },
  {
    id: 8,
    image: FLUTTER_INVOICE_PDF_GENERATOR,
    title: "An app to generate invoices as PDFs (mobile, desktop)",
    github: "https://github.com/mohammed-najib/flutter_invoice_pdf_generator",
    demo: null,
    site: null,
  },
  {
    id: 9,
    image: FLUTTER_PDF_VIEWER,
    title:
      "Project of a pdf viewer app that is multiplatform (mobile, desktop, site)",
    github: "https://github.com/mohammed-najib/flutter_pdf_viewer",
    demo: null,
    site: null,
  },
  {
    id: 10,
    image: FLUTTER_CAPTURING_AND_PLAYING_AUDIO,
    title: "A Flutter Project to capture and play audio (mobile)",
    github:
      "https://github.com/mohammed-najib/flutter_capturing_and_playing_audio",
    demo: null,
    site: null,
  },
  {
    id: 11,
    image: VUE3_EXPENSE_TRACKER,
    title: "An expense tracker Web app built with Vue3 (site)",
    github: "https://github.com/mohammed-najib/vue3-expense-traker",
    demo: "https://vue3-expense-tracker.mohammed-najib.me/",
    site: null,
  },
  {
    id: 12,
    image: IMG_KNOWLEDGE_BANK,
    title: "Knowledge Bank to assist the user to find relative info (site)",
    github: "https://github.com/mohammed-najib/retail_site_knowledge_bank",
    demo: null,
    site: null,
  },
  {
    id: 13,
    image: NEXTJS_GENERATIVE_AI_PALM2,
    title: "Chat bot using PaLM 2 chat generation model (site)",
    github: "https://github.com/mohammed-najib/nextjs_generative_ai_palm2",
    demo: null,
    site: null,
  },
  // {
  //     id: 3,
  //     image: IMG3,
  //     title: 'Figma dashboard UI kit for data design web apps',
  //     github: 'https://github.com',
  //     demo: '#',
  // },
  // {
  //     id: 4,
  //     image: IMG4,
  //     title: 'Maintaining tasks and tracking progress',
  //     github: 'https://github.com',
  //     demo: '#',
  // },
  // {
  //     id: 5,
  //     image: IMG5,
  //     title: 'Charts templates & infographics in Figma',
  //     github: 'https://github.com',
  //     demo: '#',
  // },
  // {
  //     id: 6,
  //     image: IMG6,
  //     title: 'Charts templates & infographics in Figma',
  //     github: 'https://github.com',
  //     demo: '#',
  // },
]

function Portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo, site }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt="portfolio1" />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {github && (
                  <a href={github} className="btn" target={`_blank`}>
                    Github
                  </a>
                )}
                {demo && (
                  <a href={demo} className="btn btn-primary" target={`_blank`}>
                    Live Demo
                  </a>
                )}
                {site && (
                  <a href={site} className="btn btn-primary" target={`_blank`}>
                    Live Site
                  </a>
                )}
              </div>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Portfolio
