import React, { useRef } from "react"
import "./contact.css"
import { MdOutlineEmail } from "react-icons/md"
// import { RiMessengerLine } from 'react-icons/ri';
// import { BsWhatsapp } from 'react-icons/bs';
import emailjs from "@emailjs/browser"

function Contact() {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_krugoci",
        "template_fpj5zov",
        form.current,
        "IGtlZXAInPOYL0uEY"
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )

    e.target.reset()
  }

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact_option-icon" />
            <h4>Email</h4>
            <h5>contact@mohammed-najib.me</h5>
            <a
              href="mailto:contact@mohammed-najib.me"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
          {/* <article className="contact__option">
                        <RiMessengerLine className='contact_option-icon' />
                        <h4>Messenger</h4>
                        <h5>Mohammed Najeeb</h5>
                        <a href="https://m.me/mohammed.najeeb3" target='_blank'>Send a message</a>
                    </article>
                    <article className="contact__option">
                        <BsWhatsapp className='contact_option-icon' />
                        <h4>WhatsApp</h4>
                        <h5>+967777777777</h5>
                        <a href="https://wa.me/15551234567" target='_blank'>Send a message</a>
                    </article> */}
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          />
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
