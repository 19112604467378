import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { FaChrome, FaGithub, FaGooglePlay, FaWindows } from "react-icons/fa"
// import { FiDribbble } from 'react-icons/fi';

function HeaderSocials() {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/mohammed-najib/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/mohammed-najib"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>
      <a
        href="https://play.google.com/store/apps/dev?id=8244552868210248800"
        target="_blank"
        rel="noreferrer"
      >
        <FaGooglePlay />
      </a>
      <a
        href="https://chromewebstore.google.com/detail/ihjdncddpjjoeldmmahkbdhkiiifgjhe"
        target="_blank"
        rel="noreferrer"
      >
        <FaChrome />
      </a>
      <a
        href="https://apps.microsoft.com/search/publisher?name=Mohammed+Najib&hl=en-in&gl=IN"
        target="_blank"
        rel="noreferrer"
      >
        <FaWindows />
      </a>
      {/* <a href="https://dribbble.com" target="_blank"><FiDribbble /></a> */}
    </div>
  )
}

export default HeaderSocials
